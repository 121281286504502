<template>
  <div>
    <b-card>
      <b-tabs v-model="tabIndex">
        <b-tab title="ตรวจสอบรายการ">
          <b-card>
            <b-row>
              <b-col
                xl="3"
                sm="12"
              >
                <b-card>
                  <h3 style="text-align: center;">
                    คุณกำลังใส่ HN <b style="color:#7367f0"><u>{{ hn }}</u></b>
                  </h3>
                  <h2
                    class="mb-1"
                    style="text-align: center;"
                  >
                    ตรวจสอบรายละเอียด
                  </h2>
                  <div>
                    <perfect-scrollbar>
                      <b-list-group>
                        <b-list-group-item
                          v-for="(data, index) in userOptions"
                          :key="index"
                          button
                          @click="saveImageData(data.value)"
                        >
                          {{ data.value.name }}
                        </b-list-group-item>
                      </b-list-group>
                      <!-- <b-form-select
                        v-model="imageData"
                        :options="userOptions"
                        text-field="value.name"
                        :select-size="10"
                      /> -->
                    </perfect-scrollbar>
                    <!-- <b-form-select
                      v-model="imageData"
                      :options="userOptions"
                      text-field="value.name"
                      :select-size="10"
                    /> -->
                  </div>
                  <b-button
                    v-b-modal.modal-1
                    class="mt-1"
                    block
                    variant="primary"
                    @click="showImageSelect()"
                  >
                    บันทึก
                  </b-button>
                </b-card>
              </b-col>
              <b-col
                xl="9"
                sm="12"
              >
                <b-card>
                  <selectedViewer
                    :key="imageData.type + imageData.name"
                    :image-data="imageData"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <b-tab title="ดาวน์โหลดเอกสาร PDF">
          <template #title>
            <feather-icon icon="DownloadIcon" />
            <span>ดาวน์โหลดเอกสาร PDF</span>
          </template>
          <b-card>
            <b-table-lite
              hover
              responsive
              sticky-header="true"
              :fields="fieldPdf"
              :items="keepTypePdf"
              :per-page="perPage"
              :current-page="currentPage"
              selectable
            >
              <template #cell(hn)="data">
                <b-badge
                  variant="light-primary"
                  class="badge-glow"
                >
                  <span>{{ data.item.hn }}</span>
                </b-badge>
              </template>
              <template #cell(download)="row">
                <b-button
                  pill
                  variant="outline-primary"
                  :disabled="setDisabled(row.item)"
                  @click="set_infoPdf(row.item, row.index, $event.target)"
                ><feather-icon icon="DownloadIcon" /> Download
                </b-button>
              </template>
            </b-table-lite>
            <br>
            <b-button
              id="mr"
              variant="primary"
              type="filled"
              icon="arrow_downward"
              :disabled="disabled_MR"
              block
              pill
              style="display: inline"
              @click="setPdf(keepTypePdf)"
            ><feather-icon icon="DownloadIcon" /> {{ disabled_MR === true ? "กรุณารอสักครู่" : "Download ALL" }}
            </b-button>
          </b-card>
        </b-tab>
        <b-tab title="ส่ง Email">
          <template #title>
            <feather-icon icon="MailIcon" />
            <span>ส่ง Email</span>
          </template>
          <b-card>
            <sendEmail
              :key="hn"
              :hn="hn"
            />
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal
      id="modal-1"
      title="สรุปใบที่ต้องการลบ"
      ok-only
      ok-title="บันทึก"
      size="xl"
      @ok="saveRecordData()"
    >
      <b-card-text>
        <h1 class="text-center mb-3 mt-2">
          <u>
            กรุณาตรวจสอบรายการที่ลบอย่างละเอียดก่อนบันทึก
          </u>
        </h1>
        <p style="font-size: 1.5em; text-align: center;">
          คุณไม่ได้เลือกเอกสาร สามารถกดบันทึกเพื่อไปหน้าถัดไป หรือปิดหน้าเพื่อย้อนกลับ
        </p>
        <div v-viewer="{movable: false}">
          <b-row>
            <b-col
              v-for="(data, index) in imageDiscardList"
              :key="index"
              lg="2"
              md="4"
              sm="6"
              class="removePadding"
            >
              <img
                class="mb-1"
                v-bind="imageConfig"
                :src="data.src"
                style="border: 1px solid black;  box-shadow: 4px 6px;"
                :alt="`Image ${index + 1}`"
              >
              <b-button
                variant="danger"
                class="ml-5 mb-1 mt-1"
                @click="removeImage(index)"
              >
                ลบ
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-text>
    </b-modal>
    <!-- Control buttons-->
    <div class="text-center">
      <b-button-group class="mt-1">
        <b-button
          variant="outline-primary"
          @click="tabIndex--"
        >
          ย้อนหลัง
        </b-button>
        <b-button
          variant="outline-primary"
          @click="tabIndex++"
        >
          ถัดไป
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>

import VueViewer from 'v-viewer'
import Vue from 'vue'
import { jsPDF } from 'jspdf'
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import sendEmail from '../../components/testMail.vue'
import selectedViewer from './selectedViewer.vue'
import store from '../../../../store'

Vue.use(VueViewer)
export default {
  components: {
    sendEmail,
    selectedViewer,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      testHN: 0,
      selectDocType: null,
      options: [
        { value: null, text: 'เลือกประเภทเอกสาร' },
        { value: 1, text: 'MR' },
        { value: 2, text: 'EMR' },
        { value: 3, text: 'IPD' },
        { value: 4, text: 'เอกสารอื่น' },
      ],
      imageConfig: {
        blank: false,
        blankColor: '#bbb',
        width: 200,
        height: 250,
      },
      subOptions: [],
      userOptions: [],
      render: false,
      hn: 0,
      imageData: {},
      subOptionsTemp: [],
      tabIndex: 0,
      imageDiscardList: [],
      imageUrls_MR: [],
      fieldPdf: [
        {
          key: 'hn', label: 'hn',
        },
        'type',
        'name',
        'download',
      ],
      loadingsubOptions: false,
      keepTypePdf: [],
      imageList: [],
      keepSetHieRecordDiscard: [],
      imageListAfter: [],
      imageList_NoCutoff: [],
      keepClinicIPDAmount: null,
      offsetIPD: [],
      disabled_MR: null,
      ImageIPD: [],
      keepIPD_base64: [],
      keepimageListAfter: [],
      testObgOffset: [
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
        ['20', '21', '22', '23', '24', '25', '26', '27', '28', '29'],
        ['30', '31', '32', '33', '34', '35', '36', '37', '38', '39'],
        ['40', '41', '42', '43', '44', '45', '46', '47', '48', '49'],
        ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
        ['60', '61', '62', '63', '64', '65', '66', '67', '68', '69'],
        ['70', '71', '72', '73', '74', '75', '76', '77', '78', '79'],
        ['80', '81', '82', '83', '84', '85', '86', '87', '88', '89'],
        ['90', '91', '92', '93', '94', '95', '96', '97', '98', '99'],
        ['100', '101', '102', '103', '104', '105', '106', '107', '108', '109'],
        ['110', '111', '112', '113', '114', '115', '116', '117', '118', '119'],
        ['120', '121', '122', '123', '124', '125', '126', '127', '128', '129'],
        ['130', '131', '132', '133', '134', '135', '136', '137', '138', '139'],
        ['140', '141', '142', '143', '144', '145', '146', '147', '148', '149'],
        ['150', '151', '152', '153', '154', '155', '156', '157', '158', '159'],
        ['160', '161', '162', '163', '164', '165', '166', '167', '168', '169'],
        ['180', '181', '182', '183', '184', '185', '186', '187', '188', '189', '190'],
        ['190', '191', '192', '193', '194', '195', '196', '197', '198', '199', '200'],
      ],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      hnRequest: null,
    }
  },
  watch: {
    async tabIndex(value) {
      console.log(value)
      if (value === 0) {
        // this.userOptions = []
        this.subOptionsTemp.forEach(element => this.userOptions.push({ value: element }))
        this.keepTypePdf = []
      } else if (value === 1) {
        this.set_ImgOther()
      } else {
        this.imageData = {}
        this.keepTypePdf = []
      }
      // eslint-disable-next-line no-unused-expressions
      store.commit('hieDetail/clearHieRecord')
      // store.commit('hieDetail/clearHieRecordDiscard')
    },
  },
  created() {
    // console.log('this.$route.params', this.$route.params.hn[0])
    // eslint-disable-next-line prefer-destructuring
    this.hnRequest = this.$route.params.hn[0]
    this.saveHN(this.hnRequest)
  },
  methods: {
    async saveHN(value) {
      this.hn = value
      store.commit('hieDetail/getHN', this.hn)
      this.selectDocType = null
      this.subOptions = []
      await this.mrList()
      await this.emrList()
      await this.ipdList()
      await this.documentList()
    },
    saveImageData(value) {
      this.imageData = value
    },
    async mrList() {
      await this.$http({
        // url: `api/HIE/MRDoc/${this.hn}`,
        url: `api/HIE/MRDoc/${this.hn}`,
        method: 'GET',
      }).then(({ data }) => {
        data.forEach(element => this.userOptions.push({ value: { type: 'MR', data: element, name: `MR / ${element.logDate} ${element.location} ${element.managedBy}` } }))
      })
    },
    async emrList() {
      await this.$http({
        url: `api/HIE/EMRDoc/${this.hn}`,
        method: 'GET',
      }).then(({ data }) => {
        data.forEach(element => this.userOptions.push({ value: { type: 'EMR', data: element, name: `EMR / ${element.logDate} ${element.location} ${element.managedBy}` } }))
      })
    },
    async ipdList() {
      await this.$http({
        url: `api/HIE/IPDDocDate/${this.hn}`,
        method: 'GET',
      }).then(({ data }) => {
        data.forEach(element => this.userOptions.push({ value: { type: 'IPD', data: element, name: `IPD / ${element.clinicDate}` } }))
      })
    },
    async documentList() {
      this.$http({
        url: `api/HIE/OtherDocType/${this.hn}`,
        method: 'GET',
      }).then(({ data }) => {
        data.forEach(element => this.userOptions.push({ value: { type: 'Other', data: element, name: `${element.typeName}` } }))
      })
    },
    async saveRecordData() {
      // store.dispatch('hieDetail/saveRecord')
      store.dispatch('hieDetail/saveRecord', this.userOptions)
      this.tabIndex = 1
    },
    async showImageSelect() {
      this.imageDiscardList = store.getters['hieDetail/setHieRecordDiscard']
    },
    // /////////////////// PDF //////////////////////////
    async setPdf(keepTypePdf) {
      // console.log('setPdf', keepTypePdf)
      let index = 0
      for (index; index < keepTypePdf.length; index += 1) {
        if (keepTypePdf[index].type === 'Other') {
          // eslint-disable-next-line no-await-in-loop
          await this.$http({
            url: `api/Image/AllImageOther/${this.hn}/${keepTypePdf[index].name}`,
            method: 'GET',
          }).then(({ data }) => {
            data.forEach(element => {
              this.imageList.push({ src: `data:image/jpeg;base64, ${element.imageBase64}`, imageBaseName: element.imageBaseName })
              this.imageList_NoCutoff.push(`data:image/jpeg;base64, ${element.imageBase64}`)
            })
          })
          // eslint-disable-next-line no-await-in-loop
          await this.methodsPrint(keepTypePdf[index])
        } else if (keepTypePdf[index].type === 'MR') {
          // eslint-disable-next-line no-await-in-loop
          await this.$http({
            url: `api/Image/AllImageMR/${this.hn}/${keepTypePdf[index].data.logDate}/${keepTypePdf[index].data.managedBy}/${keepTypePdf[index].data.location}`,
            method: 'GET',
          }).then(({ data }) => {
            data.forEach(element => {
              this.imageList.push({ src: `data:image/jpeg;base64, ${element.imageBase64}`, imageBaseName: element.imageBaseName })
              this.imageList_NoCutoff.push(`data:image/jpeg;base64, ${element.imageBase64}`)
            })
          })
          // eslint-disable-next-line no-await-in-loop
          await this.methodsPrint(keepTypePdf[index])
        } else if (keepTypePdf[index].type === 'IPD') {
          console.log('IPD')
          // eslint-disable-next-line no-await-in-loop
          await this.$http({
            url: '/api/Image/ClinicIPDAmount',
            method: 'POST',
            headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
            data: {
              hn: this.hn,
              clinic: keepTypePdf[index].data.clinicDate,
            },
          }).then(response => {
            this.keepClinicIPDAmount = response.data
          })
          // eslint-disable-next-line no-await-in-loop
          await this.cal_ipd_ofset()
          // eslint-disable-next-line no-await-in-loop
          await this.set_Pdf_IPD(keepTypePdf[index])
        }
      }
      // if (keepTypePdf === 'mr') {
      //   this.disabled_MR = true
      //   // console.log('hieDetail/setHieRecordDiscard', store.getters['hieDetail/setHieRecordDiscard'])
      //   // console.log('hieDetail/setHieRecordType', store.getters['hieDetail/setHieRecordType'])
      //   // console.log(store.getters['hieDetail/setHieRecordType'][0].value)
      //   // console.log(store.getters['hieDetail/setHieRecordType'][1].value)
      //   // console.log(store.getters['hieDetail/setHieRecordType'][2].value)
      //   await this.set_Pdf_MR()
      //   await this.set_ImgOther()
      //   await this.savePdf(this.imageUrls_MR, x, 'test')
      // } else {
      //   this.disabled_IPD = true
      //   await this.set_Pdf_IPD(x)
      // }
    },
    set_Pdf_MR() {
      let index = 0
      for (index; index < store.getters['hieDetail/setHieRecordDiscard'].length; index += 1) {
        this.imageUrls_MR[index] = `${store.getters['hieDetail/setHieRecordDiscard'][index].src}`
      }
    },
    async set_ImgOther() {
      console.log('hieDetail/setHieRecordDiscard', store.getters['hieDetail/setHieRecordDiscard'])
      // console.log('hieDetail/setHieRecordType', store.getters['hieDetail/setHieRecordType'])
      await store.getters['hieDetail/setHieRecordType'].forEach(element => this.keepTypePdf.push({
        hn: this.hn, type: element.value.type, name: `${element.value.name}`, data: element.value.data,
      }))
      this.totalRows = this.keepTypePdf.length
    },
    // eslint-disable-next-line no-unused-vars
    async set_infoPdf(item, index, button) {
      // this.infoModal.title = `Row index: ${index}`
      // this.infoModal.content = JSON.stringify(item, null, 2)
      // this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      this.keepSetHieRecordDiscard = store.getters['hieDetail/setHieRecordDiscard']
      // console.log('hieDetail/setHieRecordType', store.getters['hieDetail/setHieRecordType'])
      if (item.type === 'Other') {
        await this.$http({
        // url: '/api/Image/AllImage',
          url: `api/Image/AllImageOther/${this.hn}/${item.name}`,
          method: 'GET',
        }).then(({ data }) => {
          data.forEach(element => {
            this.imageList.push({ src: `data:image/jpeg;base64, ${element.imageBase64}`, imageBaseName: element.imageBaseName })
            this.imageList_NoCutoff.push(`data:image/jpeg;base64, ${element.imageBase64}`)
          })
        })
        await this.methodsPrint(item)
      } else if (item.type === 'MR') {
        await this.$http({
          url: `api/Image/AllImageMR/${this.hn}/${item.data.logDate}/${item.data.managedBy}/${item.data.location}`,
          method: 'GET',
        }).then(({ data }) => {
          data.forEach(element => {
            this.imageList.push({ src: `data:image/jpeg;base64, ${element.imageBase64}`, imageBaseName: element.imageBaseName })
            this.imageList_NoCutoff.push(`data:image/jpeg;base64, ${element.imageBase64}`)
          })
        })
        await this.methodsPrint(item)
      } else if (item.type === 'IPD') {
        console.log('IPD')
        await this.$http({
          url: '/api/Image/ClinicIPDAmount',
          method: 'POST',
          headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
          data: {
            hn: this.hn,
            clinic: item.data.clinicDate,
          },
        }).then(response => {
          this.keepClinicIPDAmount = response.data
        })
        await this.cal_ipd_ofset()
        await this.set_Pdf_IPD(item)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async methodsPrint(item) {
      console.log(this.imageListAfter, item)
      await this.setHieRecordDiscard_Cutoff()
      await this.savePdf(this.imageListAfter, item.type, item.name)
      await this.clearObjImage()
    },
    setHieRecordDiscard_Cutoff() {
      if (this.keepSetHieRecordDiscard.length === 0) {
        this.imageListAfter = this.imageList_NoCutoff
      } else {
        const results = this.imageList.filter(({ imageBaseName: id1 }) => !this.keepSetHieRecordDiscard.some(({ imageBaseName: id2 }) => id2 === id1))
        this.keepimageListAfter = results
        let index = 0
        for (index; index < this.keepimageListAfter.length; index += 1) {
          this.imageListAfter.push(`${this.keepimageListAfter[index].src}`)
        }
        // let index = 0
        // for (index; index < this.imageList.length; index += 1) {
        //   let indexJ = 0
        //   for (indexJ; indexJ < this.keepSetHieRecordDiscard.length; indexJ += 1) {
        //     if (this.imageList[index].imageBaseName !== this.keepSetHieRecordDiscard[indexJ].imageBaseName) {
        //       this.imageListAfter.push(`${this.imageList[index].src}`)
        //     }
        //   }
        // }
      }
    },
    clearObjImage() {
      this.imageList = []
      this.imageListAfter = []
      this.imageList_NoCutoff = []
    },
    cal_ipd_ofset() {
      let i = 0
      for (i; i < Math.ceil(Math.ceil(this.keepClinicIPDAmount / 30) / 10); i += 1) {
        let index = 0
        this.offsetIPD.push([])
        for (index; index < 10; index += 1) {
          this.offsetIPD[i][index] = 30 * this.testObgOffset[i][index]
        }
      }
      console.log('🚀', this.offsetIPD)
    },
    async set_Pdf_IPD(item) {
      let index = 0
      for (index; index < this.offsetIPD.length; index += 1) {
        let i = 0
        for (i; i < 10; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await this.$http({
            url: `api/Image/AllImageIPD/${this.hn}/${item.data.clinicDate}/${this.offsetIPD[index][i]}`,
            method: 'GET',
          // eslint-disable-next-line no-loop-func
          }).then(response => {
            this.ImageIPD.push(response.data)
            this.imgKepp(i)
          })
        }
        // eslint-disable-next-line no-await-in-loop
        await this.savePdf(this.keepIPD_base64, item.type, item.name)
        this.setImageBeforGet()
      }
      this.disabled_IPD = false
    },
    imgKepp(x) {
      let index = 0
      for (index; index < this.ImageIPD[x].length; index += 1) {
        this.keepIPD_base64.push(`data:image/png;base64,${this.ImageIPD[x][index].imageBase64}`)
      }
    },
    setImageBeforGet() {
      this.ImageIPD = []
      this.keepIPD_base64 = []
    },
    async savePdf(x, typeFile, name) {
      const multiPng = await this.generatePdf(x)

      // generate dataURLString
      // const dataURLString = multiPng.output(`'dataurlstring', 'HN_${this.MRDoc[0].patientID}_MR.pdf'`)
      // console.log(dataURLString)

      // save PDF (blocked in iFrame in chrome)
      if (typeFile === 'MR') {
        multiPng.output('save', `${typeFile}_${name}.pdf`)
      } else if (typeFile === 'IPD') {
        multiPng.output('save', `${typeFile}_${name}.pdf`)
      } else {
        multiPng.output('save', `${typeFile}_${name}.pdf`)
      }
      // this.disabled_MR = false
      // this.imageList = []
      // this.imageListAfter = []
    },
    async generatePdf(x) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF('p', 'mm', 'a4')
      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()
      // eslint-disable-next-line no-restricted-syntax
      for (const [i, url] of x.entries()) {
        // eslint-disable-next-line no-await-in-loop
        const image = await this.addImageProcess(url)
        doc.addImage(image, 'JPEG', 0, 0, width, height)
        if (i !== x.length - 1) {
          doc.addPage()
        }
      }
      return doc
    },
    async addImageProcess(src) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src
        img.onload = () => resolve(img)
        img.onerror = reject
      })
    },
    async removeImage(index) {
      this.imageDiscardList.splice(index, 1)
    },
    setDisabled(row) {
      // console.log('setDisabled', row.type)
      if (row.type === 'EMR') {
        return true
      }
      return false
    },
  },
}
</script>

<style>
.ps {
  height: 250px;
}
.removePadding{
  padding-left: 50px !important;
}
.bordertest{
  border: solid;
  height: 200px;
  overflow: hidden;
}

</style>
